import { shareID, excelFilePath, graphFileData, graphConfig } from "./authConfig";

/**
 * Attaches a given access token to a MS Graph API call. Returns information about the user
 * @param accessToken 
 */
export async function callMsGraph(accessToken) {
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;

    headers.append("Authorization", bearer);

    const options = {
        method: "GET",
        headers: headers
    };

    return fetch(graphConfig.graphMeEndpoint, options)
        .then(response => response.json())
        .catch(error => console.log(error));
}

export async function callMsGraphFile(accessToken) {
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;

    headers.append("Authorization", bearer);

    const options = {
        method: "GET",
        headers: headers
    };

    return fetch(shareID)
        .then(response => response.text())
        .then(async response => {
            return fetch(excelFilePath(response).url, options)
                .then(response => response.json())
        }).then(async response => {
            console.log(response);
            if(!response.error){
            return fetch(graphFileData(response.id, response.parentReference.driveId).workSheet, options)
                .then((response) => response.json())
            }else{
                return response;
            }
        })
        .catch(error => console.log(error));
}

export async function callMsGraphFile2(accessToken) {
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;

    headers.append("Authorization", bearer);

    const options = {
        method: "GET",
        headers: headers
    };

    return fetch(shareID)
        .then(response => response.text())
        .then(async response => {
            return fetch(excelFilePath(response).url, options)
                .then(response => response.json())
        }).then(async response => {
            if(!response.error){
            return fetch(graphFileData(response.id, response.parentReference.driveId).workSheet2, options)
                .then((response) => response.json())
            }else{
                return response;
            }
        })
        .catch(error => console.log(error));
}